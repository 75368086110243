import React, { useState } from 'react';
import '../Modal/modal.css';
import {toast} from "react-toastify";

const EventBookingModal = () => {
  const [formData, setFormData] = useState({ venueName: '', contactEmail: '', preferredTime: '', additionalDetails: '' });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiUrl = `${process.env.REACT_APP_API_URL}/email/send-email`;
    const emailContent = `Preferred Day & Time: ${formData.preferredTime}\nAdditional Details: ${formData.additionalDetails}`;
    const emailData = {
      name: formData.venueName,
      fromAddress: formData.contactEmail,
      emailContent,
      type: 'Event Booking',
    };

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(emailData),
      });

      if (!response.ok) {
        console.error('Error sending email:', response.statusText);
        toast.error('There was an error sending your request.');
        return;
      }

      toast.success('Booking request sent successfully!');
    } catch (error) {
      console.error('Error sending booking request:', error);
      toast.error('There was an error sending your request.');
    }
  };

  return (
    <div className="modal-content-container">
      <h2>Book Trivia for Your Event</h2>
      <p>Looking to add a unique touch to your event? Let’s get started with some details!</p>
      <form className="modal-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="venueName" className="inputLabel">Event Name</label>
          <input
            type="text"
            id="venueName"
            name="venueName"
            placeholder="Enter your venue's name"
            className="form-control"
            value={formData.venueName}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="contactEmail" className="inputLabel">Contact Email</label>
          <input
            type="email"
            id="contactEmail"
            name="contactEmail"
            placeholder="Enter your contact email"
            className="form-control"
            value={formData.contactEmail}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="preferredTime" className="inputLabel">Event Date and Time</label>
          <input
            type="text"
            id="preferredTime"
            name="preferredTime"
            placeholder="Enter preferred date & time"
            className="form-control"
            value={formData.preferredTime}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="additionalDetails" className="inputLabel">Additional Details</label>
          <textarea
            id="additionalDetails"
            name="additionalDetails"
            placeholder="Describe your event needs"
            className="form-control"
            value={formData.additionalDetails}
            onChange={handleChange}
          />
        </div>
        <div className="submitButton">
          <button type="submit" className="btn btn-primary">Submit Request</button>
        </div>
      </form>
    </div>
  );
};

export default EventBookingModal;
