import React, { useState } from "react";
import "./Header.css";
import logo from "../../assets/gv_beer_geek_logo.svg";
import '../../fonts.css';

const Header = () => {
    const [isOpen, setIsOpen] = useState(window.matchMedia("(min-width: 768px)").matches);

    function toggleNav() {
        const nav = document.querySelector('nav');
        if (window.matchMedia("(min-width: 768px)").matches) {
            nav.style.display = 'flex';
        } else {
            setIsOpen(!isOpen);
            nav.style.display = isOpen ? 'none' : 'block';
        }
    }

    const feedbackFormUrl = "https://docs.google.com/forms/d/e/1FAIpQLSc5nar5JfbuCdZ23fV8rI3HWLqtfKQYDrd8kfWqmDmpXCYD0A/viewform?usp=sf_link";

    return (
        <header className="header">
            <div className="headerUpperLeft">
                <div className="header_logo">
                    <img src={logo} alt="Logo"/>
                </div>
                <div className={"header_text"}>
                    <h1 className="headerText1">Grand Valley Beer Geeks</h1>
                </div>

            </div>
            <div className={"navigationContainer"}>
                <button className="burger" onClick={toggleNav}>☰</button>
                <nav style={{ display: isOpen ? 'block' : 'none' }}>
                    <ul>
                        <li><span className="arrow" /><h3><a href={"/"}>Home</a></h3></li>
                        <li><span className="arrow" />
                            <h3>
                                <a href="/checkscores">Check Scores</a>
                            </h3>
                        </li>
                        <li><span className="arrow" />
                            <h3>
                                <a href="/about">About</a>
                            </h3>
                        </li> 
                        <li><span className="arrow" />
                            <h3>
                                <a href="/workwithus">Book Us</a>
                            </h3>
                        </li>
                        <li><span className="arrow" />
                            <h3>
                                <a href={feedbackFormUrl} rel="noreferrer" target="_blank">Feedback Form</a>
                            </h3>
                        </li> 

                    </ul>
                </nav>
            </div>
        </header>
    );
};


export default Header;
