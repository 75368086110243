import React from 'react';
import PropTypes from 'prop-types';
import HowToPlayModal from "../HowToPlay/howToPlayModal"
import VenueBookingModal from '../VenueBookingModal/VenueBookingModal';
import EventBookingModal from '../EventBookingModal/EventBookingModal';
import AdBookingModal from '../AdBookingModal/AdBookingModal'
import NonProfitBookingModal from '../NonProfitBookingModal/NonProfitBookingModal'
import './modal.css';

const Modal = ({ isOpen, onClose, type }) => {
  if (!isOpen) return null;

  const renderContent = () => {
    switch (type) {
      case 'howToPlay':
        return <HowToPlayModal/>
      case 'success':
        return <div>Success! Your action was successful.</div>;
      case 'error':
        return <div>Error! Something went wrong.</div>;
      case 'warning':
        return <div>Warning! Proceed with caution.</div>;
      case 'venueBooking':
        return <VenueBookingModal />;
      case 'eventBooking':
        return <EventBookingModal />;
      case 'adBooking':
        return <AdBookingModal />;
      case 'nonProfitBooking':
        return <NonProfitBookingModal/>;
      default:
        return <div>Default content</div>;
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="close-container">
          <button className="modal-close-button" onClick={onClose}>X</button>
        </div>
        {renderContent()}
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default Modal;
