import React, { forwardRef, useState } from "react";
import Modal from "../Modals/Modal/modal.js"; // Make sure to import your modal component
import "./WorkWithUs.css";

const WorkWithUs = forwardRef((props, ref) => {
  const [activeSection, setActiveSection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState('');

  const toggleSection = (section) => {
    setActiveSection(activeSection === section ? null : section);
  };

  const openBookingModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);

  return (
    <div ref={ref} className="work-with-us">
      <div className="work-text-container">
        <h1>Book Us</h1>

        <div className="section">
          <h2 className="section-header" onClick={() => toggleSection(1)}>
            Get Trivia at Your Venue
          </h2>
          <div className={`section-content ${activeSection === 1 ? "open" : ""}`}>
            <span>
              Bring the energy of Beer Geek Trivia
              to your venue! Hosting a weekly trivia night not only fills seats but creates
              a loyal community of regulars who return for the thrill of friendly competition.
              {/* Additional content as needed */}
            </span>
            <button className="book-button" onClick={() => openBookingModal('venueBooking')}>
              Book Now
            </button>
          </div>
        </div>

        <div className="section">
          <h2 className="section-header" onClick={() => toggleSection(2)}>
            Get Trivia at Your Event
          </h2>
          <div className={`section-content ${activeSection === 2 ? "open" : ""}`}>
            <span>
              Looking for a unique and engaging way to celebrate? Beer Geek Trivia offers
              one-off trivia events perfect for birthdays, corporate team-building, fundraisers,
              and club gatherings.
              {/* Additional content as needed */}
            </span>
            <button className="book-button" onClick={() => openBookingModal('eventBooking')}>
              Book Now
            </button>
          </div>
        </div>

        <div className="section">
          <h2 className="section-header" onClick={() => toggleSection(3)}>
            Get Your Ad on Trivia
          </h2>
          <div className={`section-content ${activeSection === 3 ? "open" : ""}`}>
            <span>
              Capture the attention of an engaged, local audience by advertising with Beer Geek Trivia!
              Our half-sheet visual rounds are seen by over a hundred players every week across multiple venues.
              {/* Additional content as needed */}
            </span>
            <button className="book-button" onClick={() => openBookingModal('adBooking')}>
              Inquire Now
            </button>
          </div>
        </div>
        <div className="section">
          <h2 className="section-header" onClick={() => toggleSection(4)}>
            Non Profit? No Cost Ads
          </h2>
          <div className={`section-content ${activeSection === 4 ? "open" : ""}`}>
            <span>
              We love to support the local community that comes to and enjoys our events.
              {/* Additional content as needed */}
            </span>
            <button className="book-button" onClick={() => openBookingModal('nonProfitBooking')}>
              Inquire Now
            </button>
          </div>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={closeModal} type={modalType} />
    </div>
  );
});

WorkWithUs.displayName = "WorkWithUs";

export default WorkWithUs;
