// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../fonts.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../../colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.how-to-play-content {
    padding: 20px;
    text-align: left;
    font-family: Arial, sans-serif;
    display: flex;
    justify-content: center;
    font-family: 'Nickson', sans-serif;
    flex-direction: column;
  }
  
  .how-to-play-content h3 {
    text-align: center;
    color: var(--secondary-color);
    font-size: 24px;
    margin-bottom: 16px;
  }
  
  .how-to-play-content ol {
    padding-left: 20px;
    margin-bottom: 16px;
    
  }
  p {
    color: gray;
    display: inline;
    font-size: 12px;
    
  }
  
  .how-to-play-content li {
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 1.5;
    color: var(--text-color); /* Text color from your palette */

  }
  
  .how-to-play-content li:last-child {
    margin-bottom: 0;
  }
  
  .how-to-play-content li a strong {
    color: var(--accent-color) !important /* Highlight important parts using your highlight color */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Modals/HowToPlay/howToPlayModal.css"],"names":[],"mappings":"AAGA;IACI,aAAa;IACb,gBAAgB;IAChB,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,kCAAkC;IAClC,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;;EAErB;EACA;IACE,WAAW;IACX,eAAe;IACf,eAAe;;EAEjB;;EAEA;IACE,mBAAmB;IACnB,eAAe;IACf,gBAAgB;IAChB,wBAAwB,EAAE,iCAAiC;;EAE7D;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE,oCAAoC,EAAE,yDAAyD;EACjG","sourcesContent":["@import url('../../../fonts.css');\n@import url('../../../colors.css');\n\n.how-to-play-content {\n    padding: 20px;\n    text-align: left;\n    font-family: Arial, sans-serif;\n    display: flex;\n    justify-content: center;\n    font-family: 'Nickson', sans-serif;\n    flex-direction: column;\n  }\n  \n  .how-to-play-content h3 {\n    text-align: center;\n    color: var(--secondary-color);\n    font-size: 24px;\n    margin-bottom: 16px;\n  }\n  \n  .how-to-play-content ol {\n    padding-left: 20px;\n    margin-bottom: 16px;\n    \n  }\n  p {\n    color: gray;\n    display: inline;\n    font-size: 12px;\n    \n  }\n  \n  .how-to-play-content li {\n    margin-bottom: 10px;\n    font-size: 16px;\n    line-height: 1.5;\n    color: var(--text-color); /* Text color from your palette */\n\n  }\n  \n  .how-to-play-content li:last-child {\n    margin-bottom: 0;\n  }\n  \n  .how-to-play-content li a strong {\n    color: var(--accent-color) !important /* Highlight important parts using your highlight color */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
