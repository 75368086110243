import React from 'react';
import './howToPlayModal.css'; // Create this CSS file to style the modal

const HowToPlayModal = () => {
  return (
    <div className="how-to-play-content">
      <h3>Quiz Rules</h3>
      <ol>
        <li>Dont cheat <p>(if you have to ask if its cheating it is)</p></li> 
        <li>Dont shout out answers</li>
      </ol>
      <h3>How to Play</h3>
      <ol>
        <li>Grab an Answer Sheet, Visual Round, and Pencil</li>
        <li>Text in your Team Name and Number of players to <a href="sms:+19702815238"><strong>(970)-281-5238</strong></a></li>
        <li>Listen for Round 1 to start!</li>
        <p>In the mean time Work on that visual round to be ready to send it in with round 4</p>
      </ol>
      <h3>Quiz Format</h3>
      <ol>
        <li>6 Rounds <p>you can double one per quiz</p></li>
        <li>5 questions per round <p>(8 for visual)</p></li>
        <li>The questions will be read twice the first time</li>
        <li>All questions are repeated at the end of the round</li>
        <li>
          After all questions are repeated, listen for the song for the round <p>(usually has something to do with the round or an answer)</p>
        </li>
        <li>Decide your answers, decide if you wanna double, then send your answers in 1 text <p>(photo or typed)</p> to <a href="sms:+19702815238"><strong>(970)-281-5238</strong></a></li>
      </ol>
    </div>
  );
};

export default HowToPlayModal;
