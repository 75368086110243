import React, { forwardRef } from "react";
import "./Schedule.css";
import handlebar from "../../assets/handlebar_tap.png";
import gemini from "../../assets/gemini_beer_logo.png";
import cruise_control from "../../assets/cruise_control_logo.png";
import deroco from "../../assets/deroco.png"
import '../../fonts.css';
//import { useEffect } from 'react';
//import { toast } from 'react-notifications'; // Assuming you're using react-notifications


const Schedule = forwardRef(() => {
    // useEffect(() => {
    // const fetchData = async () => {
    //     const today = new Date();
    //     const isoDate = today.toISOString().split('T')[0];
    //     const apiUrl = `${process.env.REACT_APP_API_URL}/get_all_future_events?date=${encodeURIComponent(isoDate)}`;
    //     try {
    //         const response = await fetch(apiUrl, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //         });
    //
    //         if (!response.ok) {
    //             toast.error('Error fetching data');
    //             throw new Error('Failed to fetch data');
    //         }
    //
    //         toast.success('Data fetched successfully');
    //         // Process response data if needed
    //         const data = await response.json();
    //         console.log('Fetched data:', data);
    //     } catch (error) {
    //         console.error('Error fetching data:', error.message);
    //         toast.error('Error fetching data');
    //     }
    // };
    //     fetchData();
    // }, []);
    const dates = [
        new Date("2024-11-12T20:30:00-06:00"),
        new Date("2024-11-14T20:30:00-06:00"),
        new Date("2024-11-17T20:30:00-06:00"),
        new Date("2024-11-22T19:30:00-06:00"),
        
    ];
    const venues = [

        "Handlebar Tap House",
        "Gemini Beer Company",
        "Cruise Control",
        
        "Deroco Cellars"
        
    ];

    const logos = [

        handlebar,
        gemini,
        cruise_control,
        
        deroco
        
    ];

    const links = [
        "https://www.handlebargj.com/",
        "https://geminibeer.com/",
        "https://www.cruisecontrolgj.com/",
        "https://www.derococellars.com/",
        ]

    return (
        <div className="upcoming-dates-container">
            <h2 className="title">Upcoming Dates</h2>
            <ul className="dates-list">
                {dates.map((date, index) => (
                    <li key={index} className="date-item">
                        <a href={links[index]} target={'_blank'} rel={'noreferrer'}>
                            <div className="business-info">
                                <img src={logos[index]} alt="Business Logo" className="logo"/>
                                <span className="business-name">{venues[index]}</span>
                            </div>
                        </a>

                        {/*Make the cards links to their websites
                        {take care of offset scroll on mobile
                        */}
                        <div className="date-item-labels">
                            <div className="day">{date.toLocaleDateString('en-US', {
                                weekday: 'long',
                                timeZone: 'America/Denver'
                            })}</div>
                            <div className={"rightHand"}>
                                <div className="date">{date.toLocaleDateString('en-US', {
                                    month: 'long',
                                    day: 'numeric',
                                    timeZone: 'America/Denver'
                                })}</div>
                                <div className="time">{date.toLocaleTimeString('en-US', {
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true,
                                    timeZone: 'America/Denver'
                                })}</div>
                            </div>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
});


Schedule.displayName = "Schedule";

export default Schedule;
