import React from "react";
import styles from "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import Schedule from "./components/Schedule/Schedule";
import AdminLogin from "./components/AdminLogin/AdminLogin";
import MainLayout from "./components/MainLayout/MainLayout";
import CheckScores from "./components/CheckScores/checkscores";
import PresentationCreator from "./components/PresentationCreator/PresentationCreator";
import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WorkWithUs from "./components/WorkWithUs/WorkWithUs";
import About from "./components/About/About";
function App() {
    return (
        <>
            <ToastContainer />
            <MainLayout>
                <Router>
                    <div className={styles.App}>
                        <Routes>
                            <Route
                                path="/about"
                                element={<About />}
                            />
                             <Route
                                path="/workwithus"
                                element={<WorkWithUs/>}
                            />
                            <Route
                                path="/checkscores"
                                element={<CheckScores />}
                            />
                            <Route
                                path="/adminlogin"
                                element={<AdminLogin />}
                            />
                            <Route
                                path="/presentationcreation"
                                element={<PresentationCreator />}
                            />
                            <Route
                                path="/"
                                element={
                                    <>
                                        <Home />
                                        <Schedule />
                                    </>
                                }
                            />
                        </Routes>
                    </div>
                </Router>
            </MainLayout>
        </>
    );
}

export default App;
