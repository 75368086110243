import React, { forwardRef } from "react";
import "./About.css";
import imageOne from "../../assets/Trvia-Night-1080p-color-0021.jpg"
import imageTwo from "../../assets/Trvia-Night-1080p-color-0024.jpg"
import imageThree from "../../assets/Trvia-Night-1080p-color-0006.jpg"
const About = forwardRef((props, ref) => {
  return (
    <div ref={ref} className="about">
    
      <div className="body-text-container">
      <h1>Homebrewed Trivia</h1>
        <div>
        <img className="imageOne" src={imageOne} />
          <span>
          Beer Geek Trivia has been quizzing party-goers, mountain bikers, college students, Kiwanis club members, brew enthusiasts, couples, and anyone lucky enough to stop by our valley for the past three years. With over 2,000 original questions crafted and more than 300 quizzes hosted, we’re proud to be the most experienced, continually operating quizmasters in the Grand Valley.
          </span>
        </div>
        <div>
        <img className="imageOne mobile-image" src={imageTwo} />
        <span>
            Our goal is to deliver well-rounded trivia that goes beyond simply reading off factoids with question marks. Not everyone is a history buff, a movie expert, or a literature fanatic — and that’s okay! Our quizzes are designed to be inclusive, challenging, and engaging for all types of knowledge enthusiasts.
          </span>
          <img className="imageOne desktop-image" src={imageTwo} />
          
        </div>
        <div>
        <img className="imageOne" src={imageThree} />
          <span>
            At each of our regular venues, you&apos;ll find the same welcoming format but a fresh quiz every time, so you always feel right at home with new challenges. Whether you’re a seasoned quiz player or a first-timer, Beer Geek Trivia offers an exciting, friendly atmosphere to test your wits and enjoy the night.
          </span>
        </div>
      </div>
    </div>
  );
});

About.displayName = "About";

export default About;
