import React, { useState, useEffect } from "react";
import {toast} from "react-toastify";
import styles from "./checkscores.css";
import Modal from "../Modals/Modal/modal";
// import useFetchGames from "../../hooks/useFetchGames";

const CheckScores = () => {
    const [formData, setFormData] = useState({ pin: "" });
    const [pinState, setPinState] = useState(false);
    const [pinData, setPinData] = useState([]);
    const [bestSmallTeam, setBestSmallTeam] = useState(null)
    const [pageTitle, setPageTitle] = useState("Trivia Game")
    let submitter = [];

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const pin = urlParams.get('pin');
        if (pin) {
            setFormData({ pin });
            handleSubmit(null, pin);
        }
        return () => {
            // Cleanup function to avoid multiple registrations or calls
        };
    }, []);

    // const games =  useFetchGames()
    // console.log(games)
    const handleSubmit = async (e, pin) => {
        if (e) e.preventDefault();
        const pinToUse = pin || formData.pin;
        const apiUrl = `${process.env.REACT_APP_API_URL}/games/get_all_teams?pin=${encodeURIComponent(pinToUse)}`;
        try {
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            const data = await response.json();
            const today = new Date(data.game.createdAt);
            const month = today.getMonth() + 1; // JavaScript months are 0-indexed
            const day = today.getDate();

            setPageTitle(data.game.venue + " " + month + "/" + day)

            submitter = Object.entries(data.teams).map(([key, value]) => ({
                key,
                name: value.name,
                playerCount: value.player_count,
                score: value.score
            }));

            // Sort teams by score in descending order
            submitter.sort((a, b) => b.score - a.score);
            let currentPlace = 1;
            let previousScore = null;
            submitter = submitter.map((team, index) => {
                if (team.score !== previousScore) {
                    currentPlace = index + 1;
                }
                previousScore = team.score;
                return { ...team, place: currentPlace };
            });


            // Filter out teams with fewer than 6 players
            let eligibleTeams = submitter.filter(team => team.playerCount <= 6);

            // Sort eligible teams by score
            eligibleTeams.sort((a, b) => b.score - a.score);
            // Get first and second place
            eligibleTeams[0].is_first_place = true
            let i = 0
            for (let team of eligibleTeams) {
                if (team.score === eligibleTeams[0].score && team.score >0 )
                {
                    team.is_first_place = true
                    i+=1
                }
                break
            }

            let j = 1
            eligibleTeams[i].is_second_place = true
            for (let team of eligibleTeams) {
                if (team.score === eligibleTeams[i].score && team.score >0 )
                {
                    team.is_second_place = true
                    j+=1
                }
                else if (team.score < eligibleTeams[i].score){
                    break
                }
                
            }

            
            eligibleTeams = eligibleTeams.slice(j)

            let bestSmallTeamScore = 0;
            // Find all best small teams
            for (let team of eligibleTeams) {
                if (team.playerCount < 4 && team.playerCount >0 && team.score >= bestSmallTeamScore && team.score > 0) {
                    if (bestSmallTeamScore === 0){
                        setBestSmallTeam(team);
                        bestSmallTeamScore = team.score
                        team.is_best_small_team = true
                    }
                    else {
                        team.is_best_small_team = true
                    }
                }
            }

            setPinState(true);
            setPinData(submitter);

            if (!response.ok) {
                toast.error('Error with Pin',{
                    toastId: 'success1',
                });
                return;
            }

            toast.success('Pin Found',{
                toastId: 'success1',
            });
            window.history.pushState(null, '', `?pin=${encodeURIComponent(pinToUse)}`);

        } catch (error) {
            console.error('Error finding pin:', error.message);
            toast.error('Error finding pin',{
                toastId: 'success1',
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const largeTeams = pinData.filter((team)=> team.playerCount > 6).length > 0;
    const feedbackFormUrl = "https://docs.google.com/forms/d/e/1FAIpQLSc5nar5JfbuCdZ23fV8rI3HWLqtfKQYDrd8kfWqmDmpXCYD0A/viewform?usp=sf_link";
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalType, setModalType] = useState('howToPlay');
  
    const openModal = (type) => {
      setModalType(type);
      setIsModalOpen(true);
    };
  
    const closeModal = () => setIsModalOpen(false);

    return (
        <div className="scoresheet">
            {pinState ? <div>
                <div className="headerContainer" >
                    <span className="headerTitle" >{pageTitle}</span>
                    <span><a href={feedbackFormUrl} rel="noreferrer" target="_blank">Give feedback</a></span>
                </div>
                <div className="table-container">
                <table>
                    <thead>
                    <tr>
                        <th>Place</th>
                        <th>Name</th>
                        <th>Score</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pinData.map((team) => (
                        <tr key={team.key} className={styles.trStyle}>
                            <td className={styles.tdStyle}>{team.place}</td>
                            <td className={styles.tdStyle}>{team.is_best_small_team ? "🏆" : team.is_first_place ? "🥇" : team.is_second_place ? "🥈" : team.playerCount > 6 ? "❌": ""} {team.name}</td>
                            <td className={styles.tdStyle}>{team.score}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <div style={{display: "flex", flexDirection: "column", justifyContent: "center", marginTop: "20px"}}>
                        {bestSmallTeam && <div>🏆 = Best Small Team</div>}
                        {largeTeams && <div>❌ = Team too big to win</div>}
                        <div className="helpInfo">
                        <button onClick={() => { openModal('howToPlay')}}>
                    <span>
                        How to Play
                    </span>
                </button>
            </div>
                    </div>
                </div>
            </div>
            </div> : (<div className="pinInput" style={{width: "70vw", margin: "auto"}}>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="mb-3">
                            <label htmlFor="pin" className="inputLabel">Quiz Pin</label>
                            <input
                                type="text"
                                className="form-control"
                                id="pin"
                                name="pin"
                                value={formData.pin}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="submitButton">
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </div>
                        <span>Please enter pin given by the Quiz Master</span>
                        <div className="helpInfo">
                <button onClick={() => { openModal('howToPlay')}}>
                    <span>
                        How to Play
                    </span>
                </button>
            </div>
                    </form>
                </div>
            )}
            {openModal && <Modal isOpen={isModalOpen} onClose={closeModal} type={modalType} />}

        </div>
    );
};

export default CheckScores;